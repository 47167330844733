import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import moment from 'moment';
// import "moment-duration-format";

const styles = theme => ({
    durationStyle: {
        display: 'inline-block',
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: 4,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '0px 4px',
        borderRadius: 2,
        letterSpacing: .5,
        fontSize: 11,
        fontWeight: 500,
        lineHeight: '1.2rem'
      },
    
});

class VideoDuration extends React.Component {
  sec2time = (timeInSeconds) => {
    var pad = function(num, size) { 
      return ('000' + num).slice(size * -1); 
    },
    time = parseFloat(timeInSeconds).toFixed(3), // for remove string and for 4 digit
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);
   return timeInSeconds >= 3600 ? pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) : pad(minutes, 2) + ':' + pad(seconds, 2);
}
  render(){
  const { vDuration, classes} = this.props;
  // let videoDuration = moment.duration(1000, 'seconds').format(1000 >= 3600 ? "hh:mm:ss" : "mm:ss", {
  //   trim: false
  // });

  return (
    <div>
        <Typography className={classes.durationStyle}>
          {/* {videoDuration} */}
          {this.sec2time(vDuration)}
        </Typography>
    </div>
  );
}
}

VideoDuration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoDuration);
